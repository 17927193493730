import React from 'react';

const arr = [];
let x = 0;
let returnArray = [];
const returnIndicators = [];

class Slider extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            counter: 0,
            timeout: null
        };
        this.counterSwitchHandler = ( e ) => {
            this.setState( { counter: e.currentTarget.id } );
        };

    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }

    createSlider() {
        if (this.props && this.props.sliderCache && this.props.sliderCache.div) {
            if ( x < this.props.sliderCache.div[ 0 ].div.length ) {
                if (this.state.timeout === null) {
                    const timeout = setTimeout(function() {
                        this.setState( { counter: x, timeout: null } );
                    }.bind(this), 5000);
                    this.setState({timeout});
                    x += 1;
                }
                if ( x >= this.props.sliderCache.div[ 0 ].div.length ) {
                    x = 0;
                }
            }
            if ( this.props ) {
                this.props.sliderCache.ol[ 0 ].li.map( ( i, key ) => {
                    returnIndicators[ key ] = (
                        <li key={ key } data-target="#carousel"
                            data-slide-to={ key } id={ key }
                            onClick={ this.counterSwitchHandler.bind( this ) }
                            className={ this.state.counter == key ? 'active' : '' }
                        />
                    );
                } );
                returnArray = [];
                this.props.sliderCache.div[ 0 ].div.map( ( i, key ) => {
                    returnArray[ key ] = (
                        <div key={ key } className="carousel-inner" role="listbox">
                            <div className={ `slider_responsive item ${ this.state.counter == key ? 'active' : '' }` }>
                                <div className={ i.div[ 0 ].$.class }>
                                    <div className={ i.div[ 0 ].div[ 0 ].$.class } />
                                    <div className={ i.div[ 0 ].div[ 1 ].$.class } id="line_1" />
                                    <div className={ i.div[ 0 ].div[ 2 ].$.class } />
                                </div>
                                <div className={ i.div[ 1 ].$.class }>
                                    <img
                                        src={  i.div[ 1 ].img[ 0 ].$.src }
                                        className={ i.div[ 1 ].img[ 0 ].$.class + " img-fluid"}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                } );
            }
        }
    }


    render() {
        this.createSlider();
        return (
            <div id="carousel" className="carousel" data-ride="carousel">
                <ol className="carousel-indicators">
                    {returnIndicators}
                </ol>
                {returnArray}
            </div>
        );
    }
}

export default Slider;
